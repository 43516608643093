const lenderNotFoundHandler = (e) => {
    if (!window.OneSignal) {
        return;
    }

    console.log(`OneSignal: decision.lender_not_found`, e.detail);

    window.OneSignal.isOptedOut().then(isOptedOut => {
        if (isOptedOut) {
            return;
        }

        window.OneSignal.isPushNotificationsEnabled().then(isEnabled => {
            if (isEnabled) {
                const applicationDecision = e.detail.applicationDecision;
                const firstName = (applicationDecision && applicationDecision.applicantNameFirst) ? (applicationDecision.applicantNameFirst + ', ') : '';

                window.OneSignal.sendSelfNotification(
                    `${firstName}You've Got Options`,
                    `We’re sorry, but a lender is unable to work with you at this time. Don’t worry, there are other options!`,
                    'https://billshappenusa.com/rest/redirect/decision',
                    'https://billshappenusa.com/assets/images/sack-of-cash.png'
                );
            }
        });
    });
};

export default [
    {
        type: 'form.submit',
        handler: (e) => {
            if (!window.OneSignal || !window.OneSignal.isPushNotificationsEnabled()) {
                return;
            }

//            console.log(`OneSignal: form.submit`, e.details);

            if (e.detail.formData && e.detail.formData.email) {
                window.OneSignal.setEmail(e.detail.formData.email);
            }
        }
    },
    {
        type: 'olp.submit',
        handler: (e) => {
            if (!window.OneSignal) {
                return;
            }

//            console.log(`OneSignal: olp.submit`, e.detail);

            window.OneSignal.isOptedOut().then(isOptedOut => {
                if (isOptedOut) {
                    return;
                }

                window.OneSignal.isPushNotificationsEnabled().then(isEnabled => {
                    if (!isEnabled) {
                        window.OneSignal.showHttpPrompt();
                    }
                });
            });
        }
    },
    {
        type: 'billable',
        handler: (e) => {
            if (!window.OneSignal) {
                return;
            }

            window.OneSignal.isOptedOut().then(isOptedOut => {
                if (isOptedOut) {
                    return;
                }

                window.OneSignal.isPushNotificationsEnabled().then(isEnabled => {
                    if (isEnabled) {
                        const applicationDecision = e.detail.applicationDecision;
                        const firstName = (applicationDecision && applicationDecision.applicantNameFirst) ? (' ' + applicationDecision.applicantNameFirst) : '';

                        window.OneSignal.sendSelfNotification(
                            `Congrats${firstName}! A lender is ready to work with you`,
                            `Click here to connect with your lender now.`,
                            'https://billshappenusa.com/rest/redirect/decision',
                            'https://billshappenusa.com/assets/images/sack-of-cash.png'
                        );
                    }
                });
            });
        }
    },
    {
        type: 'decision.lender_found',
        handler: (e) => {
            if (!window.OneSignal) {
                return;
            }

//            console.log(`OneSignal: decision.lender_found`, e.detail);
        }
    },
    {
        type: 'decision.lender_not_found',
        handler: lenderNotFoundHandler
    },
    {
        type: 'decision.exit_path',
        handler: lenderNotFoundHandler
    },
    {
        type: 'decision',
        handler: (e) => {
            if (!window.OneSignal) {
                return;
            }

            const { outcome, applicationDecision } = e.detail;

            window.OneSignal.push(() => {
                window.OneSignal.sendTags({
                    last_decision: outcome,
                    name_first: applicationDecision.applicantNameFirst || null,
                    email: applicationDecision.applicantEmail || null,
                    address_state: applicationDecision.applicantHomeAddressState || null,
                    applied_multiple_times: applicationDecision.appliedMultipleTimes || false,
                    mpc: applicationDecision.mpc || null
                }).then(function(tagsSent) {
                    console.log(tagsSent);
                });
            });
        }
    },
    {
        type: 'pageview.response',
        handler: (e) => {
            if (!window.OneSignal) {
                return;
            }

            const { pageViewResponse} = e.detail;
            const applicant = pageViewResponse.applicant;

            const tags = {
                browser_uuid: pageViewResponse.buid,
                mobile: pageViewResponse.mobile,
                last_promo_id: pageViewResponse.cpanelClick.promoId
            };

            if (applicant.homeAddress && applicant.homeAddress.state) {
                tags['address_state'] = applicant.homeAddress.state;
            }

            if (applicant.email) {
                tags['email'] = applicant.email;
                window.OneSignal.setEmail(applicant.email);
            }

            if (applicant.nameFirst) {
                tags['name_first'] = applicant.nameFirst;
            }

            window.OneSignal.push(() => {
                window.OneSignal.sendTags(tags).then(function(tagsSent) {
                    console.log(tagsSent);
                });
            });

            window.OneSignal.push(function() {
                window.OneSignal.setExternalUserId(pageViewResponse.buid);
            })
        }
    }
];
